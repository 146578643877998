
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Item } from '../store/models/ItemModel';
import { OrdersService } from '@/services/orders-service';
import DialogLayout from './DialogLayout.vue';
import RollDirectionAlert from '@/components/RollDirectonAlert.vue';
import OrderForm from '@/components/OrderForm.vue';
import PaymentAdd from '@/components/PaymentAdd.vue';
import PaymentTerms from '@/components/PaymentTerms.vue';
import { QuickDialog, AlertModel } from '../store/models/DialogModel';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import {
  RushOption,
  Orderline,
  OrderlineDetails,
  OrderlinePurchaseStateTypes
} from '@/store/models/OrderlineModel';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { CustomerService } from '@/services/customer-service';
import { CustomerPaymentModel, PaymentProfile } from '@/store/models/PaymentModel';
import { DialogHelper } from '@/helpers/DialogHelper';
import { Confirm } from '@/helpers/AuthHelper';
import OrderlinePreflight from './OrderlinePreflight.vue';
import PaymentSelectCard from '@/components/cardComponents/PaymentSelectCard.vue';
@Component({
  components: {
    DialogLayout,
    RollDirectionAlert,
    OrderForm,
    PaymentAdd,
    PaymentTerms,
    OrderlinePreflight,
    PaymentSelectCard
  }
})
export default class ItemOrderDialog extends Vue {
  $refs!: {
    OrderForm: HTMLFormElement;
    PaymentAdd: HTMLFormElement;
    PaymentTerms: HTMLFormElement;
    OrderlinePreflight: HTMLFormElement;
  };
  /* Properties */
  @Prop({ required: true }) item!: Item;
  @Prop({ default: false }) isOrdering: boolean;

  /* Store Actions */
  /* Watchers */

  @Watch('dialog')
  onDialogChange(val: any) {
    if (!val) {
      this.closeDialog();
    }
  }
  /* Data */
  dialog: boolean = false;
  data: QuickDialog | null = null;
  isLoading: boolean = false;
  totalPrice: string = '$0.00';
  extendedPrice: number = 0;
  variableDataFee: number = 0;
  rushFee: number = 0;
  setUpFee: number = 0;
  extraRollFee: number = 0;
  orderlines: OrderlineDetails[] = [];
  isCalculatingPrice: boolean = false;
  isPlacingOrder: boolean = false;
  paymentProfile: PaymentProfile = new PaymentProfile();
  paymentError: string = '';
  orderId: string = '';
  /* Methods */
  async hasPastDueInvoices() {
    const { data } = await OrdersService.GetPastDueOrders();
    if (data)
      Confirm(
        () => {
          this.goToInvoices();
        },
        'You have invoices past due',
        'You have invoices past due, would you like to pay them now?',
        "I'll pay them later",
        'Yes'
      );
  }
  goToInvoices() {
    window.open(`${process.env.VUE_APP_BASE_URL}/invoicemanagement`);
  }
  onPlaceOrderPressed() {
    this.$refs.OrderlinePreflight.runPreflightCheck(() => {
      this.QuickOrder();
    });
  }
  setPaymentProfile(profile: any) {
    this.paymentProfile = profile;
  }
  setOrderId(orderId: string) {
    this.orderId = orderId;
  }
  setPO(po: string) {
    this.orderlines[0].PO = po;
  }

  async GeneratePricingData(orderlines: OrderlineDetails[]) {
    this.isCalculatingPrice = true;
    this.paymentError = '';
    if (orderlines) {
      const response = await OrdersService.CalculatePricingData(
        orderlines.map(ol => ConversionHelper.convertOrderlineToAddModel(ol))
      );
      const orderlinesWithPricing = response.data.map(
        (ol: any) => new OrderlineDetails(ol)
      );
      this.orderlines = orderlinesWithPricing;
      this.resetFees();
      response.data.forEach((ol: any) => {
        this.extendedPrice += ol.Quantity * ol.PriceEach;
        this.setUpFee += ol.SetupCosts;
        this.variableDataFee += ol.Quantity * ol.VariableDataPriceEach;
        this.rushFee = ol.RushOption.RushFee;
        this.extraRollFee = ol.HandApplyFee;
      });
    } else {
      this.resetFees();
    }
    this.isCalculatingPrice = false;
  }
  setPricingLoader(val: boolean) {
    if (val) this.isCalculatingPrice = val;
  }
  resetFees() {
    this.extendedPrice = 0;
    this.setUpFee = 0;
    this.variableDataFee = 0;
    this.rushFee = 0;
    this.extraRollFee = 0;
  }

  async QuickOrder() {
    if (this.canPlaceOrder()) {
      this.isPlacingOrder = true;
      try {
        let model = {
          Orderlines: this.orderlines.map((ol: OrderlineDetails) =>
            ConversionHelper.convertOrderlineToAddModel(ol)
          ),
          PO: this.orderlines[0].PO,
          State: 'New',
          ShipToId: 4,
          PaymentMethodProfileId: this.paymentProfile.PaymentProfileId,
          OrderType: 'Order Now',
          OrderId: this.orderId
        };
        const { data } = await OrdersService.PlaceOrder(model);
        SetSnackBar('Item ordered successfully');
        this.$emit('refreshItemAndPo', this.orderlines[0].PO);
        this.$emit('refreshCustomerOrder', data[0].OrderId);
        this.closeDialog();
      } catch (err) {
        const error = err as any;
        if (
          error.response &&
          !error.response.data.ExceptionMessage.includes('Object reference')
        ) {
          this.paymentError = error.response.data.ExceptionMessage;
        } else {
          this.paymentError =
            'Issue has occured while placing your order. Please try again or contact technical support if issue persists';
        }
      }
      this.isPlacingOrder = false;
    }
  }
  canPlaceOrder() {
    if (this.$refs.OrderForm)
      return (
        this.$refs.OrderForm.canPlaceOrder() &&
        !!this.paymentProfile?.Id &&
        !this.paymentProfile.IsExpired
      );
    return false;
  }
  closeNewPaymentDialog() {
    this.$refs.PaymentAdd.closeNewPaymentDialog();
  }
  openNewPaymentDialog() {
    this.$refs.PaymentAdd.openNewPaymentDialog();
  }

  async openDialog(orderline?: OrderlineDetails) {
    this.data = ConversionHelper.convertQuickDialog(this.item);
    this.dialog = true;
    if (orderline) {
      setTimeout(() => {
        this.$refs.OrderForm.setData(orderline);
      }, 500);
    }
    this.hasPastDueInvoices();
  }
  closeDialog() {
    this.dialog = false;
  }
  /* Computed */
  get orderTotal() {
    return this.extendedPrice + this.rushFee + this.extraRollFee;
  }
  get processingFee() {
    return this.paymentProfile?.IsNet ? 0 : this.orderTotal * 0.025;
  }
  get calculateSubTotal() {
    return `$${(this.orderTotal + this.processingFee).toFixed(2)}`;
  }
  /* Mounted */
  /* Created */
  async created() {}
}
