import { render, staticRenderFns } from "./ItemOrderDialog.vue?vue&type=template&id=9ab8dbd6&v-if=dialog"
import script from "./ItemOrderDialog.vue?vue&type=script&lang=ts"
export * from "./ItemOrderDialog.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports